
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, sendOutline, helpCircleOutline,
        createOutline, openOutline, chevronForwardOutline, informationCircleOutline, personAddOutline, pencil,
        downloadOutline, star, starOutline, arrowDown, addCircleOutline, settingsOutline } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        IonFab, IonFabButton, IonRange, IonInput, IonModal,
        loadingController, modalController, alertController, } from '@ionic/vue';
import UserProfileFormModal from '@/components/modals/UserProfileFormModal.vue';
import UserDetailsModal from '@/components/modals/UserDetailsModal.vue';
import AchieveJUPASResultPageModal from '@/components/achievejupas/AchieveJUPASResultPageModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

// services
import TeacherService from '@/services/TeacherService';
import AchieveJUPASService from '@/services/AchieveJUPASService';

// types
import { Session, Tag, User } from '@/types';

import { jsPDF } from 'jspdf';

export default defineComponent({
  name: 'TeacherAchieveJUPASModal',
  props: ["isPage", "isDemo", "hideHeader"],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
                IonFab, IonFabButton, IonRange, IonInput, IonModal, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, presentToast, infiniteScrollLoadData, formatStudentNumber,
            getQRCodeUrl, sendLinkToMyWhatsApp, getF6YearDSE, getF5YearDSE, randInt, uniqueId, } = utils();
    const { t } = useI18n();
    const router = useRouter();

    const closeModal = async () => {
      if (props.isPage) router.replace('/home');
      else await modalController.dismiss({})
    };
  
    const user = computed(() => store.state.user);
    const schoolStudents = computed<User[]>(() => store.state.schoolStudents);
    const visibleStudents = computed(() => {
      const demoStudents: any = [];
      for (const cl of ['5X', '5Y', '6X', '6Y']) {
        const numOfStudents = randInt(91, 95);
        const possibleLikedProgramCounts = [0, 3, 6, 20]; // possibilities
        for (let studentNumber = 90; studentNumber < numOfStudents; studentNumber++) {
          const demoStudent = {
            ...user.value,
            class: cl, studentNumber, fullName: 'Demo Student', chineseName: '',
            yearDSE: cl.startsWith('5') ? getF5YearDSE() : getF6YearDSE(),
            isDemo: true, likedProgramCount: possibleLikedProgramCounts[randInt(0, possibleLikedProgramCounts.length-1)],
          }
          demoStudents.push(demoStudent);
        }
      }
      if (user.value.isAdmin || schoolStudents.value.length === 0) {
        // Show also demo students for admin users / schools without any registered students (new schools)
        return [
          ...demoStudents,
          ...schoolStudents.value.filter(s => s.schoolId != 'beacon1'),
        ];
      }
      return schoolStudents.value;
    });
    const searchKeyword = ref("");
    const filters = reactive({
      yearDSE: "all",
      studentClass: "all",
      status: "all",
      custom: [] as string[],  // Changed to array for multiple selection
    })

    // Toggle custom filter tags
    const toggleCustomFilter = (tagId: any) => {
      if (tagId === 'All') filters.custom = [];
      else {
        const index = filters.custom.indexOf(tagId);
        if (index === -1) filters.custom.push(tagId);
        else filters.custom.splice(index, 1);
      }
    };

    // Infinite Scroll
    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, visibleStudents.value, 50);
    }

    // Get count of programs with 'like' reaction
    const getStudentProgramCount = (student: User) => {
      if (student.isDemo) return student.likedProgramCount || 0;
      return (student.userPrograms || []).filter(up => up.reaction === 'like').length;
    };

    // Open user details modal
    const openUserDetails = async (student: User) => {
      return await openModal(AchieveJUPASResultPageModal, { targetUser: student });
    };

    // Get unique DSE years
    const dseYears = computed(() => {
      const uniqueYrs = [...new Set(visibleStudents.value.map(s => Number(s.yearDSE)).filter(year => year))];
      return uniqueYrs.sort((a, b) => b - a); // Sort descending
    });

    // Export student data as PDF
    const exportStudentData = async (student: User) => {
      try {
        const loading = await loadingController.create({
          message: 'Generating PDF...',
        });
        await loading.present();

        // Get student's program selections
        const programSelections = (student.userPrograms || [])
          .filter(up => up.reaction === 'like')
          .map(up => {
            const program = store.state.allPrograms.find(p => p.id === up.programId);
            return program ? `${program.jupasCode} - ${program.name}` : '';
          })
          .filter(Boolean);

        // Create PDF
        const doc = new jsPDF();
        const margin = 20;
        let y = margin;

        // Add school logo if available
        const school = store.state.schools.find(s => s.id === student.schoolId);
        if (school?.logoLink) {
          doc.addImage(school.logoLink, 'JPEG', margin, y, 30, 30);
          y += 40;
        }

        // Title
        doc.setFontSize(20);
        doc.text('Student JUPAS Profile', margin, y);
        y += 15;

        // Basic Information
        doc.setFontSize(14);
        doc.text('Basic Information', margin, y);
        y += 10;

        // Student details
        doc.setFontSize(12);
        const details = [
          `Name: ${student.fullName}`,
          `Class: ${student.class}${formatStudentNumber(student.studentNumber)}`,
          `DSE Year: ${student.yearDSE || 'N/A'}`,
          student.phone ? `Phone: ${student.phone}` : null,
          student.email ? `Email: ${student.email}` : null,
          student.studyingElectives ? `Electives: ${student.studyingElectives}` : null,
        ].filter(Boolean);

        details.forEach((detail: any) => {
          doc.text(detail, margin, y);
          y += 8;
        });
        y += 5;

        // Selected Programs
        doc.setFontSize(14);
        doc.text('Selected JUPAS Programs', margin, y);
        y += 10;
        doc.setFontSize(12);

        if (programSelections.length === 0) {
          doc.text('No programs selected yet', margin, y);
          y += 8;
        } else {
          programSelections.forEach((prog, index) => {
            if (y > 270) {
              doc.addPage();
              y = margin;
            }
            doc.text(`${index + 1}. ${prog}`, margin, y);
            y += 8;
          });
        }

        // Save PDF
        doc.save(`${student.fullName}_JUPAS_Profile.pdf`);
        await loading.dismiss();
        await presentToast('PDF generated successfully', 2000);
      } catch (error) {
        console.error('Error generating PDF:', error);
        await presentToast('Error generating PDF', 2000);
      }
    };


    // Filter students based on all criteria
    const filteredSchoolStudents = computed(() => {
      let filteredStudents = visibleStudents.value.slice();

      // Year DSE filter
      if (filters.yearDSE !== 'all') {
        filteredStudents = filteredStudents.filter(s => s.yearDSE == parseInt(filters.yearDSE));
      }
      
      // Text search
      if (searchKeyword.value) {
        const cleanedKeyword = searchKeyword.value.replace(/[^a-z0-9]/gi, "").toLowerCase();
        filteredStudents = filteredStudents.filter((s: User) => {
          const searchInWords = `${s.fullName} ${s.chineseName} ${s.preferredName} ${s.phone} ${s.email} ${s.class}${s.studentNumber}`
            .replace(/[^a-z0-9]/gi, "").toLowerCase();
          return searchInWords.includes(cleanedKeyword);
        });
      }

      // Class filter
      if (filters.studentClass !== 'all') {
        filteredStudents = filteredStudents.filter(s => s.class === filters.studentClass);
      }

      // Custom filter (multiple tags - AND condition)
      if (filters.custom.length > 0) {
        filteredStudents = filteredStudents.filter(s => 
          filters.custom.every(tag => s.tagIds?.includes(tag))
        );
      }

      // AchieveJUPAS completion status filter
      // TODO: missing target scores etc.
      if (filters.status !== 'all') {
        filteredStudents = filteredStudents.filter(s => {
          const programCount = getStudentProgramCount(s);
          return filters.status === 'complete' ? programCount >= 20 : programCount < 6;
        });
      }

      // Sort by DSE year (desc), then class, then student number
      return filteredStudents.sort((a, b) => {
        if (a.yearDSE !== b.yearDSE) return (b.yearDSE || 0) - (a.yearDSE || 0);
        const classA = a.class?.toUpperCase() || '';
        const classB = b.class?.toUpperCase() || '';
        if (classA !== classB) return classA.localeCompare(classB);
        return (a.studentNumber || '').toString().localeCompare(b.studentNumber || '');
      });
    });


    // Sorting
    const sortConfig = ref({
      field: 'class',  // Default to no sorting
      ascending: true
    });

    const toggleSortDirection = () => {
      sortConfig.value.ascending = !sortConfig.value.ascending;
    };

    const getSortedStudents = computed(() => {
      const students = [...filteredSchoolStudents.value];
      
      if (!sortConfig.value.field) return students; // Return unsorted if no sort field selected
      
      const sortedStudents = students.sort((a, b) => {
        let compareA, compareB;
        
        switch (sortConfig.value.field) {
          case 'class':
            compareA = a.class + formatStudentNumber(a.studentNumber);
            compareB = b.class + formatStudentNumber(b.studentNumber);
            break;
          case 'studentNumber':
            compareA = Number(a.studentNumber);
            compareB = Number(b.studentNumber);
            break;
          case 'fullName':
            compareA = a.fullName;
            compareB = b.fullName;
            break;
          case 'completion':
            compareA = getStudentProgramCount(a);
            compareB = getStudentProgramCount(b);
            break;
          default:
            return 0;
        }

        if (compareA < compareB) return sortConfig.value.ascending ? -1 : 1;
        if (compareA > compareB) return sortConfig.value.ascending ? 1 : -1;
        return 0;
      });
      
      if (user.value.isAdmin) {
        return [
          ...sortedStudents.filter(s => s.isDemo), // show demo students first
          ...sortedStudents.filter(s => !s.isDemo),
        ];
      }
      return sortedStudents;
    });

    // Tag management
    const getUserDefinedTags = () => (user.value.createdTags || []);
    const isAddingTag = ref(false);
    const isManagingTags = ref(false);
    const currEditingTag = ref<Tag | null>(null);
    const newTagName = ref("Group " + (getUserDefinedTags().length + 1));
    const selectedStudents = ref<{ [key: string]: boolean }>({});

    const startAddingTag = () => {
      filters.custom = []; // remove existing filters
      isAddingTag.value = true;
      newTagName.value = "Group " + (getUserDefinedTags().length + 1);
      selectedStudents.value = {};
    };

    const cancelAddingTag = () => {
      isAddingTag.value = false;
      newTagName.value = "";
      selectedStudents.value = {};
    };

    const canSaveTag = computed(() => {
      return newTagName.value.trim() !== '';
      //return newTagName.value.trim() !== '' && Object.values(selectedStudents.value).some(v => v) && !getUserDefinedTags().some(t => t.name.toLowerCase() == newTagName.value.trim().toLowerCase());
    });

    const saveNewTag = async () => {
      if (!canSaveTag.value) return;

      const loading = await loadingController.create({ duration: 20000 });
      await loading.present();

      // Tag name & ID
      const tagId = currEditingTag.value?.id || `t${uniqueId()}`; // ID in database
      const tagName = newTagName.value.trim();

      // Add tag to selected students
      const studentsToUpdate = filteredSchoolStudents.value.filter(s => selectedStudents.value[s.id]);
      studentsToUpdate.forEach(student => {
        if (!student.tagIds) student.tagIds = [];
        if (!student.tagIds.includes(tagId)) {
          student.tagIds.push(tagId);
        }
      });

      // Save to DB & update store
      AchieveJUPASService.upsertTeacherDefinedTag(tagId, tagName, studentsToUpdate.map(s => s.id));
      store.commit('upsertUserCreatedTags', [{ id: tagId, name: tagName }]);

      // Select only the new tag
      filters.custom = [tagId];

      // Reset the add tag state
      cancelAddingTag();

      loading.dismiss();
    };

    const openManageTags = () => {
      isManagingTags.value = true;
    };

    const editTag = async (tag: Tag) => {
      // Switch to edit mode
      isManagingTags.value = false;
      isAddingTag.value = true;
      newTagName.value = tag.name;
      currEditingTag.value = tag;

      // Pre-select students with this tag
      selectedStudents.value = {};
      filteredSchoolStudents.value.forEach(student => {
        if (student.tagIds?.includes(tag.id)) {
          selectedStudents.value[student.id] = true;
        }
      });

      // Release filter (to allow adding other students)
      filters.custom = [];
    };

    const deleteTag = async (tag: Tag) => {
      const alert = await alertController.create({
        header: 'Delete Tag',
        message: `Are you sure you want to delete "${tag.name}"?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Delete',
            role: 'destructive',
            handler: async () => {
              const loading = await loadingController.create({ message: 'Deleting tag...' });
              await loading.present();

              // Remove tag from all students
              filteredSchoolStudents.value.forEach(student => {
                if (student.tagIds?.includes(tag.id)) {
                  student.tagIds = student.tagIds.filter(t => t !== tag.id);
                }
              });

              // Remove from filters if selected
              filters.custom = filters.custom.filter(tagId => tagId != tag.id);

              // Delete from DB
              AchieveJUPASService.deleteTeacherDefinedTag(tag.id);
              store.commit('deleteUserCreatedTag', tag.id);

              loading.dismiss();
            },
          },
        ],
      });
      await alert.present();
    };

    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline, sendOutline, helpCircleOutline, createOutline, openOutline,
      chevronForwardOutline, informationCircleOutline, personAddOutline, pencil,
      downloadOutline, star, starOutline, arrowDown, addCircleOutline, settingsOutline,

      // variables
      user,
      searchKeyword,
      dseYears,

      filters, // selected filters
      toggleCustomFilter, // custom groups

      // Tag management
      getUserDefinedTags,
      isAddingTag,
      isManagingTags,
      newTagName,
      selectedStudents,
      startAddingTag,
      cancelAddingTag,
      saveNewTag,
      canSaveTag,
      editTag, deleteTag,

      // Infinite scroll
      loadData, numOfVisibleItems,

      // methods
      t, getQRCodeUrl, sendLinkToMyWhatsApp,
      closeModal,
      formatStudentNumber,
      allClasses: () => {
        let checkStudents = visibleStudents.value;
        if (filters.yearDSE != 'all') {
          checkStudents = checkStudents.filter(s => s.yearDSE == filters.yearDSE);
        }
        return [...new Set(checkStudents.map(s => s.class?.replace(/[^a-z0-9]/gi, "").toLowerCase()))]
                .filter(c => !!c).map((c: any) => c?.toUpperCase()).sort();
      },
      getStudentProgramCount,
      openUserDetails,
      exportStudentData,

      // Sorting
      sortConfig,
      toggleSortDirection,
      getSortedStudents,
      openManageTags,
      getNumOfStudentsOfTag: (tagId: any) => {
        return visibleStudents.value.filter(s => s.tagIds?.includes(tagId)).length;
      },
    };
  },
});
